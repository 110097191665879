@import "~styles/colors";

.container {
  display: flex;
  height: 40px;
  border-radius: 8px;
  border: 1px solid $borderColor;
}

.cell {
  border-radius: 8px 0 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  cursor: pointer;
}

.left {
  border-radius: 8px 0 0 8px;
  border-right: 1px solid $borderColor;
}

.right {
  border-radius: 0 8px 8px 0;
}

.cell:hover {
  background-color: darken($whiteColor, 2);
}

.cell:active {
  background-color: darken($whiteColor, 6);
}
