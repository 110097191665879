@import "~styles/colors";
@import "~styles/font";

.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 1;
  z-index: 100;
}

.header {
  display: flex;
  background: $whiteColor;
  height: 52px;
  align-items: center;
  padding: 0 16px;
  border-bottom: 1px solid $borderColor;
}

.backButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.backButton:hover {
  opacity: 0.8;
}

.backButton span {
  @extend %buttonFont;
  color: $mediumGreyColor;
}

.contentContainer {
  display: flex;
  flex: 1;
}
