@import "~styles/colors";
@import "~styles/font";

.container {
  background: $whiteColor;
  border: 1px solid $borderColor;
  border-radius: 8px;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid $borderColor;
  height: 64px;
}

.steps {
  @extend %bodyFontFamily;
  color: $mediumGreyColor;
  font-size: 17px;
  font-weight: $regular;
  width: 64px;
  text-align: center;
}

.stepper {
  cursor: pointer;
}

.stepper:hover {
  opacity: 0.8;
}

.viewer {
  position: relative;
}

.current {
  stroke: $primaryColor;
  opacity: 1;
}

.normal {
  stroke: $blackColor;
  transition: 0.6s stroke ease-out;
}

.svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
