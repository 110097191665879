.container {
  display: flex;
  flex-direction: row;
}

.canvas {
  width: 400px;
  height: 400px;
  background: #2e2f30;
  margin-right: 32px;
}

.title {
  font-family: museo-sans-rounded;
  font-weight: 700;
  font-size: 24px;
  color: #bdbec0;
  text-align: left;
  margin: 0 0 32px;
  padding: 0;
}
