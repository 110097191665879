@import "~styles/colors";
@import "~styles/font";

.wordEditor,
.sentencePreview {
  margin-bottom: 16px;
}

.wordEditorSelect > div:first-child {
  border-radius: 8px 8px 0 0 !important;
}
.wordEditorSelect > div:first-child:active {
  border-radius: 8px 8px 0 0 !important;
}

.wordEditorContent {
  border-radius: 0 0 8px 8px;
  border-left: 1px solid $borderColor;
  border-right: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
  padding: 16px;
}

.selectWordLabel {
  @extend %subtitleFont;
}

.selectRowBody {
  @extend %bodyFont;
}

.audioRow {
  display: flex;
}

.audioInput {
  flex: 1;
  margin-right: 8px;
}
