@import "~styles/colors";

.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #232323;
}

.statusContainer {
  background: #2e2f30;
  width: 264px;
  padding: 32px 16px;
}

.statusTitle {
  font-family: museo-sans-rounded;
  color: #bdbec0;
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 32px 0;
}

.statusRow {
  display: flex;
  text-align: center;
  margin-bottom: 16px;
}

.statusRowLabel {
  color: #979698;
  font-size: 17px;
  line-height: 24px;
  font-weight: 500;
  margin-left: 16px;
}

.videoContainer {
  display: flex;
  align-self: flex-start;
  background: #2e2f30;
  border-radius: 8px;
  border: 1px solid #575657;
  padding: 8px;
  margin: 32px;
}

.canvas {
  width: 1080px;
  height: 1080px;
}
