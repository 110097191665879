@import "~styles/colors";

.skeleton {
  position: relative;
  overflow: hidden;
}

.skeleton::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    #ededed,
    rgba(255, 255, 255, 0)
  );
  animation: progress 1s ease-in-out infinite;
}

@keyframes progress {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.container {
  display: flex;
  flex: 1;
  border-radius: 8px;
  background-color: $borderColor;
  @extend .skeleton;
}
