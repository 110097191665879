@import "~styles/colors";

$avatarSize: 26px;

.container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.avatar {
  width: $avatarSize;
  height: $avatarSize;
  border-radius: $avatarSize;
  background: $borderColor;
  border: 2px solid $borderColor;
  margin-left: -8px;
}

.animate {
  animation: enter 800ms ease-in-out;
}

@keyframes enter {
  0% {
    transform: scale3d(0, 0, 0);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1);
  }
  40% {
    transform: scale3d(0.98, 0.98, 1);
  }
  60% {
    transform: scale3d(1.05, 1.05, 1);
  }
  80% {
    transform: scale3d(1.01, 1.01, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
