@import "~styles/colors";
@import "~styles/font";

.container {
  border-top: 1px solid $borderColor;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.count {
  @extend %subtitleFont;
}
