@import "~styles/colors";
@import "~styles/font";

.container {
  display: flex;
  margin-bottom: 16px;
}

.label {
  width: 116px;
  @extend %subtitleFont;
}

.children {
  flex: 1;
}
