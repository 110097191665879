@import "~styles/colors";
@import "~styles/font";

.container {
  background: $whiteColor;
  border-radius: 8px;
  border: 1px solid $borderColor;
  display: flex;
  flex-direction: column;
}

.sentenceContainer {
  border-bottom: 1px solid $borderColor;
  padding: 32px 16px;
  display: flex;
  align-items: center;
  border-radius: 8px 8px 0 0;
  background: linear-gradient(135deg, #6d7385, #3a3f55);
}

.statsContainer {
  padding: 16px;
  display: flex;
}

.sentence {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
}

.pinyin,
.hanzi {
  @extend %bodyFont;
  font-size: 17px;
  color: $whiteColor;
}

.english {
  @extend %bodyFont;
  font-size: 15px;
  color: $whiteColor;
  margin-top: 8px;
  opacity: 0.8;
}

.audioButton {
  width: 28px;
  height: 28px;
  border: 1px solid $borderColor;
}

.statsCount {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.vocabCount {
  @extend %subtitleFont;
}

.statsList {
  @extend %subtitleFont;
}

.statsValue {
  @extend %subtitleFont;
  color: $blackColor;
  margin-right: 4px;
}

.progressRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.progressBar {
  width: 72px !important;
  margin-right: 8px;
}
