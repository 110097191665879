@import "~styles/colors";

.input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: 40px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
}

.input:hover {
  opacity: 0.9;
}

.input:active {
  transform: scale(0.95);
  opacity: 0.4;
}

.loading {
  cursor: default;
}

.loading:hover {
  opacity: 1;
}

.loading:active {
  transform: scale(1);
  opacity: 1;
}

.disabled {
  background-color: $borderColor !important;
  cursor: default;
}
