@import "~styles/colors";
@import "~styles/font";

.header {
  border-bottom: 1px solid $borderColor;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.header span {
  @extend %subtitleFont;
}

.content {
  margin-bottom: 32px;
  width: 100%;
  height: 100%;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: $backgroundColor;
  }

  ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border: solid 3px $backgroundColor;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $primaryColor;
  }
}
