@import "~styles/colors";
@import "~styles/font";

.actionBar {
  display: flex;
  margin-bottom: 16px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.title {
  @extend %titleFont;
}

.id {
  color: $mediumGreyColor;
  font-size: 13px;
  @extend %bodyFontFamily;
  font-weight: $regular;
}

.button {
  width: 96px;
  margin-left: 16px;
}

.userMeta {
  display: flex;
  flex-direction: row;
  margin: 16px 0 32px;
  align-items: center;
}

.lastUpdatedBy {
  margin-right: 32px;
}

.presence {
  flex: 1;
}
