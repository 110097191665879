.path {
  stroke-dasharray: 200;
  animation: path 3s linear infinite;
}
@keyframes path {
  to {
    stroke-dashoffset: 400;
  }
}

.circle {
  opacity: 1;
  animation: circle 3s ease-in-out infinite;
}
@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
