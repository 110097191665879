@import "~styles/colors";
@import "~styles/font";

.container {
  background: $whiteColor;
  width: 200px;
  overflow-y: auto;
  border-left: 1px solid $borderColor;
}

.cellContainer {
  height: 32px;
  padding: 0 12px 0 24px;
  margin: 0 12px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  color: $mediumGreyColor;
  font-size: 14px;
  justify-content: space-between;
}

.cellContainer:hover {
  color: $blackColor;
  cursor: pointer;
}

.active {
  color: $whiteColor;
  background-color: $primaryColor;
}

.active:hover {
  color: $whiteColor;
}

.topNav {
  border-bottom: 1px solid $borderColor;
  margin-bottom: 12px;
  display: flex;
  height: 40px;
  justify-items: center;
  align-items: center;
  padding: 0 8px;
}

.iconAction {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 4px;
}

.iconAction:hover {
  border-radius: 8px;
  background: #f2f2f2;
}

.iconAction:hover path {
  fill: $primaryColor;
}
