@import "~styles/colors";

.container {
  display: flex;
  align-items: center;
  background: $whiteColor;
  border: 1px solid $borderColor;
  border-radius: 8px;
  height: 40px;
}

.container:hover {
  border: 1px solid darken($borderColor, 20);
}

.icon {
  margin: 0 8px;
}

.input {
  height: 40px;
  flex: 1;
  border: none;
  background: none;
  line-height: 40px;
  color: $blackColor;
  box-sizing: border-box;
  -webkit-appearance: none;
  font-size: 15px;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

.input:placeholder {
  color: $mediumGreyColor;
}
