@import "~styles/colors";

.button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $whiteColor;
  border: 1px solid $borderColor;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
  position: relative;
}

.button:hover {
  border: 1px solid darken($borderColor, 20);
}

.button:active {
  background-color: darken($whiteColor, 5);
}

.active {
  border-color: $primaryColor;
}

.input {
  width: 40px;
  height: 40px;
  opacity: 0;
  display: block;
  content: "";
  background: transparent;
  border: none;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  z-index: 2;
  cursor: pointer;
}

.icon {
  position: absolute;
  cursor: pointer;
  z-index: 1;
}
