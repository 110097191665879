@import "colors";

$regular: 500;
$bold: 700;

%titleFontFamily {
  font-family: museo-sans-rounded, serif;
  -webkit-font-smoothing: antialiased;
}

%bodyFontFamily {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}

%titleFont {
  @extend %titleFontFamily;
  color: $blackColor;
  font-weight: $bold;
  font-size: 24px;
  line-height: 1;
  margin: 0;
  padding: 0;
}

%subtitleFont {
  @extend %titleFontFamily;
  color: $mediumGreyColor;
  font-weight: $bold;
  font-size: 14px;
  line-height: 1.5;
}

%bodyFont {
  @extend %bodyFontFamily;
  color: $blackColor;
  font-weight: $regular;
  font-size: 14px;
  line-height: 1.6;
}

%buttonFont {
  @extend %titleFontFamily;
  color: $blackColor;
  font-weight: $bold;
  font-size: 17px;
  line-height: 1.6;
}
