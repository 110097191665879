@import "~styles/colors";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.2s ease-in-out all;
}

.title {
  font-family: museo-sans-rounded;
  font-weight: 700;
  font-size: 24px;
  color: #bdbec0;
  text-align: center;
  margin: 32px 0;
  padding: 0;
}

.button {
  font-family: museo-sans-rounded;
  align-self: stretch;
  background: rgba(21, 158, 221, 0.2);
  border: none;
  color: $primaryColor;
  padding: 0 32px;
  font-size: 24px;
  font-weight: 700;
  height: 44px;
  border-radius: 44px;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
  margin-top: 16px;
}

.button:hover {
  background: rgba(21, 158, 221, 0.3);
}

.button:active {
  opacity: 0.5;
}
