@font-face {
  font-family: "museo-sans-rounded";
  src: url("MuseoSansRounded-700-webfont.woff2") format("woff2"),
    url("MuseoSansRounded-700-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "museo-sans-rounded";
  src: url("MuseoSansRounded-500-webfont.woff2") format("woff2"),
    url("MuseoSansRounded-500-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "museo-sans";
  src: url("MuseoSans-300-webfont.woff2") format("woff2"),
    url("MuseoSans-300-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
