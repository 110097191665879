.content {
  flex: 1;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 56px;
  right: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
}

.content::-webkit-scrollbar {
  display: none;
}
