@import "~styles/colors";
@import "~styles/font";

.container {
  background: $whiteColor;
  border-radius: 8px;
  border: 1px solid $borderColor;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.gradient {
  height: 312px;
  display: flex;
  flex-grow: 1;
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
  position: relative;
}

.definitionTitle {
  display: inline-block;
  margin-bottom: 16px;
  @extend %buttonFont;
}

.defRow {
  margin-bottom: 16px;
  display: flex;
}

.speechPart {
  width: 72px;
  display: inline-block;
  color: $mediumGreyColor;
  font-weight: $regular;
  font-size: 17px;
  @extend %bodyFontFamily;
}

.def {
  font-size: 17px;
  @extend %bodyFontFamily;
  color: $blackColor;
  font-weight: $regular;
}

.pinyin {
  font-size: 24px;
  @extend %titleFontFamily;
  color: $blackColor;
  font-weight: $bold;
}

.hanziSvg {
  width: 100%;
  height: auto;
  max-height: 220px;
  filter: drop-shadow(0 8px 4px rgba(0, 0, 0, 0.3));
  fill: $whiteColor;
}

.hanzis {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hanzi {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.audioButton {
  position: absolute;
  left: 16px;
  bottom: 16px;
}

.frequency {
  margin-left: 8px;
  color: $mediumGreyColor;
  font-weight: $regular;
  font-size: 17px;
  @extend %bodyFontFamily;
}
