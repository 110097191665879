@import "~styles/colors";
@import "~styles/font";

.viewer {
  margin-bottom: 32px;
}

.editRow {
  display: flex;
}

.editInput {
  flex: 1;
  margin-right: 8px;
}
