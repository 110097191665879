@import "~styles/colors";

.input {
  width: 100%;
  padding: 0 24px;
  height: 48px;
  border-radius: 48px;
  border: 2px solid rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0.12);
  color: $whiteColor;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  -webkit-appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover {
  border: 2px solid rgba(255, 255, 255, 0);
  -webkit-text-fill-color: $whiteColor;
  -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0) inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill:focus {
  border: 2px solid rgba(255, 255, 255, 0.12);
}

.input:placeholder {
  color: whiteColor;
  opacity: 0.8;
}

.input:focus {
  border: 2px solid rgba(255, 255, 255, 0.16);
}

.errorLabel {
  display: inline-block;
  margin-top: 4px;
  color: $errorColor;
  font-size: 14px;
  font-weight: 500;
}
