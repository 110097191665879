@import "~styles/colors";
@import "~styles/font";

.container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: $backgroundColor;
}

.hanzi {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.strokes {
  fill: $blackColor;
  fill-opacity: 0.05;
  stroke-width: 1px;
  stroke: #c8c8c8;
}

.activeStroke {
  stroke: #b3b3b3;
  fill-opacity: 1;
  fill: #e9e9e9;
}

.line {
  stroke-width: 1px;
  stroke: #808080;
}

.current {
  stroke: #bfbfbf;
  stroke-opacity: 0.5;
}
