@import "~styles/colors";
@import "~styles/font";

.container {
  display: flex;
  width: 100%;
  padding: 0 16px;
  height: 32px;
  min-height: 32px;
  align-items: center;
  border-bottom: 1px solid $borderColor;
}

.skeleton {
  margin-right: 16px;
}
