.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 68px;
  color: #e4e6eb;
  background-color: #242526;
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  max-width: 512px;
  min-width: 272px;
  transition: 0.25s ease-in-out all;
  margin: 8px;
}

.enter {
  transform: translate3d(0, 120%, 0);
}

.entered {
  transform: translate3d(0, 0, 0);
}

.exit {
  transform: translate3d(0, 120%, 0);
  opacity: 0;
}

.close {
  background-color: #3a3b3c;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 12px;
}

.close:hover {
  opacity: 0.9;
}

.close:active {
  transform: scale(0.95);
  opacity: 0.4;
}

.success {
  background-color: #6aaf60;
}

.success .close {
  background-color: #4e8c45;
}
