@import "~styles/colors";

.container {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $primaryColor;
}

.form {
  width: 320px;
  margin-top: 32px;
}

.field {
  margin-bottom: 16px;
}
