@import "~styles/colors";
@import "~styles/font";

.container {
  display: flex;
  flex: 1;
}

.content {
  flex: 1;
}

.browser {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.pane {
  background: $whiteColor;
  border-left: 1px solid $borderColor;
  width: 512px;
  padding: 16px;
  overflow-y: scroll;
}

.pane::-webkit-scrollbar {
  display: none;
}
