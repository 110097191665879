@import "~styles/colors";

.circle {
  fill: $whiteColor;
  stroke: $mediumGreyColor;
  stroke-width: 2px;
  cursor: pointer;
}

.active {
  stroke: $primaryColor;
  stroke-width: 4px;
}

.activeBg {
  fill: $whiteColor;
  stroke: $mediumGreyColor;
  stroke-width: 2px;
  opacity: 0.3;
}
