@import "~styles/colors";

.audioButton {
  background-color: $whiteColor;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.02), 0 0 4px 0 rgba(0, 0, 0, 0.12);
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
  cursor: pointer;
}

.audioButton:hover {
  background-color: #f5f5f5;
}

.audioButton:active {
  transform: translateY(1px);
}
