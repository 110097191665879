.row {
  display: flex;
  text-align: center;
  margin-bottom: 16px;
}

.rowLabel {
  color: #979698;
  font-size: 17px;
  line-height: 24px;
  font-weight: 500;
  margin-left: 16px;
}
