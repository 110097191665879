@import "~styles/colors";
@import "~styles/font";

.container {
  display: flex;
  width: 100%;
  padding: 0 16px;
  height: 32px;
  align-items: center;
  border-bottom: 1px solid $borderColor;
  cursor: pointer;
}

.container:hover {
  background: lighten($backgroundColor, 2);
}

.column {
  @extend %bodyFont;
  text-overflow: ellipsis;
  overflow-x: hidden;
  padding-right: 8px;
  max-height: 32px;
}

.active {
  background: $whiteColor;
}

.cell {
  white-space: nowrap;
  overflow: hidden;
}
