@import "~styles/colors";
@import "~styles/font";

.container {
  background: $whiteColor;
  border: 1px solid $borderColor;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  margin-top: 16px;
}

.header {
  display: flex;
  align-items: center;
}

.title {
  @extend %subtitleFont;
  margin-right: 8px;
}

.row {
  margin-top: 16px;
  display: flex;
}

.select {
  flex: 1;
  margin-right: 16px;
}
