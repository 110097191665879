@import "~styles/colors";
@import "~styles/font";

$avatarSize: 26px;

.container {
  display: flex;
}

.avatar {
  width: $avatarSize;
  height: $avatarSize;
  border-radius: $avatarSize;
  background: $borderColor;
  border: 1px solid $borderColor;
}

.meta {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.title {
  @extend %subtitleFont;
  line-height: 1;
  text-transform: capitalize;
}

.date {
  color: $mediumGreyColor;
  font-size: 13px;
  @extend %bodyFontFamily;
  font-weight: $regular;
}
