@import "~styles/colors";

.track {
  width: 100%;
  height: 8px;
  border-radius: 8px;
  background: $borderColor;
}

.activeTrack {
  height: 8px;
  border-radius: 8px;
  min-width: 10px !important;
  background: $primaryColor;
}
