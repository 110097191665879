@import-normalize; /* bring in normalize.css styles */

@import "~styles/colors";

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: $backgroundColor;
  color: $blackColor;
  font-size: 17px;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}

*:focus {
  outline: none;
}
