@import "~styles/colors";

.container {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 56px;
  background-color: $primaryColor;
  align-items: center;
  flex-direction: column;
  padding: 16px 0;
}

.logo {
  margin-bottom: 32px;
}

.avatar {
  height: 32px;
  width: 32px;
  border-radius: 32px;
  margin-bottom: 8px;
  box-sizing: content-box;
  border: 2px solid rgba(255, 255, 255, 0.5);
  background: rgba(0, 0, 0, 0.1);
}

.avatar img {
  height: 32px;
  width: 32px;
  border-radius: 32px;
  box-sizing: content-box;
}

.nav {
  flex: 1;
}

.nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.navItem {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  line-height: 0;
  transition: ease-in-out 0.2s background;
  margin-bottom: 8px;
}

.navItem:hover,
.active {
  background: rgba(255, 255, 255, 0.2);
}

.navItem:active {
  opacity: 0.8;
}

.logout {
  align-items: center;
  cursor: pointer;
  justify-content: center;
  display: flex;
}
