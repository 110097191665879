@import "~styles/colors";
@import "~styles/font";

.container {
  background: $whiteColor;
  width: 132px;
  overflow-y: auto;
  padding-top: 12px;
  border-right: 1px solid $borderColor;
}

.cellContainer {
  height: 32px;
  padding-left: 24px;
  margin: 0 12px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  color: $mediumGreyColor;
  font-size: 14px;
}

.cellContainer:hover {
  color: $blackColor;
  cursor: pointer;
}

.active {
  color: $whiteColor;
  background-color: $primaryColor;
}

.active:hover {
  color: $whiteColor;
}
