@import "~styles/colors";
@import "~styles/font";

.container {
  padding: 16px 32px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
}

.title {
  @extend %titleFont;
}

.toolbar {
  margin-top: 16px;
  display: flex;
}

.search {
  flex: 1;
}

.toolbarButton {
  margin-left: 8px;
}
