.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:hover {
  opacity: 0.9;
}
