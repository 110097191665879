$whiteColor: #ffffff;
$blackColor: #080808;
$mediumGreyColor: #7a8086;

$primaryColor: #2eb1ec;
$primaryLightColor: #e7f5fb;

$backgroundColor: #f5f5f5;
$borderColor: #e6e6e6;

$successColor: #58c322;
$errorColor: #d9001a;

$darkThemeBackgroundColor: #131415;
$darkThemeBorderColor: #575657;
