@import "~styles/colors";
@import "~styles/font";

.container {
  margin: 32px;
}

.innerContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
  margin-top: 32px;
}

.subtitle {
  font-size: 20;
  font-weight: 700;
  font-family: museo-sans-rounded;
  margin: 24px 0 16px;
  padding: 0;
}

.captionCol {
  width: 500px;
  margin-right: 32px;
}

.mediasCol {
  width: 345px;
  margin-right: 32px;
}

.panel {
  background-color: $whiteColor;
  border: 1px solid $borderColor;
  border-radius: 8px;
  position: relative;
}

.caption {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  padding: 32px 96px 32px 32px;
  white-space: pre-wrap;
}

.copy {
  position: absolute;
  right: 16px;
  top: 16px;
}

.metadataContainer {
  display: flex;
  border-top: 1px solid $borderColor;
  padding: 12px 16px;
  justify-content: flex-end;
}

.metadata {
  text-align: right;
  color: $mediumGreyColor;
  font-size: 14px;
  font-weight: 400;
}

.preview {
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid $borderColor;
}

.mediaList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mediaRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 16px;
  cursor: pointer;
}

.mediaRow:hover {
  background: #fcfcfc;
}

.mediaTitle {
  font-weight: 500;
  font-size: 15px;
  color: $mediumGreyColor;
}

.button {
  background: $primaryLightColor;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  transition: 0.2s ease-in-out all;
  border: none;
  cursor: pointer;
}

.button:hover {
  transform: scale(1.2);
}

.button:active {
  transform: scale(0.9);
  background-color: $primaryColor;
}

.command {
  margin-top: 16px;
  background: $whiteColor;
  border-radius: 8px;
  border: 1px solid $borderColor;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 16px;
}

.command span {
  font-weight: 500;
  font-size: 15px;
  color: $mediumGreyColor;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  padding-right: 32px;
}

.headerTitleContainer {
  display: flex;
  align-items: center;
}

.headerTitle {
  @extend %titleFont;
  margin-right: 8px;
}

.headerSubtitle {
  @extend %titleFont;
  color: $mediumGreyColor;
  margin-left: 8px;
}

.publishContainer {
  display: flex;
  align-items: center;
}

.publishLabel {
  @extend %subtitleFont;
  margin-right: 8px;
}

.doneContainer {
  background: darken($borderColor, 10);
  width: 32px;
  height: 32px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s ease-in-out background;
}

.doneContainer:hover {
  background: darken($successColor, 10);
}

.doneIcon {
  opacity: 0.2;
}

.doneActive {
  background: $successColor;
}
