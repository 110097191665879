@import "~styles/colors";
@import "~styles/font";

.englishRow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.inputRow {
  margin-right: 16px;
  flex: 1;
}

.addRow {
  display: flex;
  align-items: center;
}

.select {
  width: 96px;
  margin-right: 8px;
}

.preview {
  margin-bottom: 32px;
}

.audioRow {
  display: flex;
}

.audioInput {
  flex: 1;
  margin-right: 8px;
}
